var exports = {};

exports = function (hljs) {
  var BACKTICK_ESCAPE = {
    begin: "`[\\s\\S]"
  };
  return {
    case_insensitive: true,
    aliases: ["ahk"],
    keywords: {
      keyword: "Break Continue Critical Exit ExitApp Gosub Goto New OnExit Pause return SetBatchLines SetTimer Suspend Thread Throw Until ahk_id ahk_class ahk_pid ahk_exe ahk_group",
      literal: "true false NOT AND OR",
      built_in: "ComSpec Clipboard ClipboardAll ErrorLevel"
    },
    contains: [BACKTICK_ESCAPE, hljs.inherit(hljs.QUOTE_STRING_MODE, {
      contains: [BACKTICK_ESCAPE]
    }), hljs.COMMENT(";", "$", {
      relevance: 0
    }), hljs.C_BLOCK_COMMENT_MODE, {
      className: "number",
      begin: hljs.NUMBER_RE,
      relevance: 0
    }, {
      className: "variable",
      //subst would be the most accurate however fails the point of highlighting. variable is comparably the most accurate that actually has some effect
      begin: "%[a-zA-Z0-9#_$@]+%"
    }, {
      className: "built_in",
      begin: "^\\s*\\w+\\s*(,|%)" //I don't really know if this is totally relevant

    }, {
      className: "title",
      //symbol would be most accurate however is higlighted just like built_in and that makes up a lot of AutoHotkey code
      //meaning that it would fail to highlight anything
      variants: [{
        begin: "^[^\\n\";]+::(?!=)"
      }, {
        begin: "^[^\\n\";]+:(?!=)",
        relevance: 0
      } // zero relevance as it catches a lot of things
      // followed by a single ':' in many languages
      ]
    }, {
      className: "meta",
      begin: "^\\s*#\\w+",
      end: "$",
      relevance: 0
    }, {
      className: "built_in",
      begin: "A_[a-zA-Z0-9]+"
    }, {
      // consecutive commas, not for highlighting but just for relevance
      begin: ",\\s*,"
    }]
  };
};

export default exports;